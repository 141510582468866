import React from 'react';
import UsuarioService from '../service/UsuarioService';

const auth = new UsuarioService()
export const Context = React.createContext()
class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: null,
      logado: false,
      ambienteLogado: ""
    };
  }

  componentDidMount() {
    const usuario = auth.usuarioCorrente()
    const ambienteLogado = auth.getAmbienteLogado();
    this.setState({usuario, ambienteLogado})
    if(usuario){
      this.setState({logado:true})
    }else{
      this.setState({logado:false})
    }
  }

  logout = () => {
    auth.logout()
    this.setState({logado:false, usuario:null})
  }

  logar= (user, ambiente) =>{
    this.setState({logado:true, usuario:user, ambienteLogado: ambiente})
  }

  setPathname = (pathname) => {
    sessionStorage.setItem('pathaname', pathname)
  }

  render(){
    return(
      <Context.Provider value={{state:this.state, logout:this.logout, logar:this.logar, setPathname:this.setPathname}} >
        {
          this.props.children
        }
      </Context.Provider>
    )
  }
}

export default Provider;