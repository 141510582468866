import 'bulma/css/bulma.css';
import React, { useContext, useEffect } from 'react';
import {
  Route, Routes, Link, useLocation, useNavigate
} from "react-router-dom";
import './App.css';
import { Context } from './context/Context';
import logoItextoExemplo from './logoItextoExemplo.png';
import Login from './pages/login/Login';
import TransactionSearch from './pages/transactionSearch/TransactionSearch';
import Transaction from './pages/transaction/Transaction';
import Etapa from './pages/etapa/Etapa';
import HandleRedirecting from './pages/handleRedirecting/HandleRedirecting';
import Info from './pages/info/Info';
import Health from './pages/health/Health';
import ambientes from './utils/ambientes';
import UsuarioService from './service/UsuarioService';

const usuarioService = new UsuarioService();

function Home() {
  const context = useContext(Context)
  const navigate = useNavigate();
  const logout = () => {
    context.logout()
  }
  const location = useLocation();
  useEffect(() => {
    if(usuarioService.usuarioCorrente() == null) {
        if(location.pathname.includes("/transacoes") || location.pathname.includes("/transacao") || location.pathname.includes("/etapa") || location.pathname.includes("/info") || location.pathname.includes("/health")) {
            context.setPathname(location.pathname)
            navigate("/login")
        }
    }

  })
  return (
    <>
      <div className="App">
      <header className="App-header p-6">
        <Link to="/">        
          <div>
            <img src={logoItextoExemplo} className="App-logo" alt="logo" />
          </div>
        </Link>
        {
          context.state.logado ? (
            <>
              <div>Ambiente: {context.state.ambienteLogado != null ? ambientes(context.state.ambienteLogado).nome : ""}</div>
              <Link to='/health' className="link__font_size link__font_color" >
                Health check
              </Link>
              <Link to= '/info' className="link__font_size link__font_color"> 
                  Info
              </Link>
              <button className="button is-alert" onClick={logout} style={{color: 'black'}}>Sair</button>

            </>
          ):""
        }
      </header>

      <div className="wrapper">
            <Routes>
              <Route path="/transacoes" element={<TransactionSearch/>}/>
              <Route path="/transacao/:id" element={<Transaction/>}/>
              <Route path="/transacao/etapa/:id" element={<Etapa/>}/>
              <Route path="/health" element={<Health/>}/>
              <Route path="/info" element={<Info/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/" element={<HandleRedirecting/>}/>
          </Routes>
      </div>
    </div>
    <footer>
        <p className="footer textAlignCenter"> Monitoramento Orquestrador - Versão {process.env.REACT_APP_VERSION}</p>  
    </footer>  
    </>
  );
}

export default Home;
