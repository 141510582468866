import React, { useContext, useState } from 'react';
import { Context } from '../../context/Context';
import UsuarioService from '../../service/UsuarioService';
import { useNavigate } from "react-router-dom";

export default function Login() {
    const context = useContext(Context)
    const [credenciais, setCredenciais] = useState({username:'', password:'', ambiente:'homol'})
    const [autenticando, setAutenticando] = useState(false)
    const navigate = useNavigate();
    const auth = new UsuarioService();

    const onChange = (event) => {
        let creds = credenciais
        creds[event.target.name] = event.target.value
        setCredenciais(creds)
    }

    const onSubmit = (event) => {
      event.preventDefault()
        if (autenticando) {
            return
        }
        setAutenticando(true)
        auth.auth(credenciais, (erro, sucesso) => {
            
            if (erro) {
                alert("Erro ao autenticar: " + erro)
                setAutenticando(false)
            } 
            if (sucesso) {
                auth.setUsuarioCorrente(sucesso)
                auth.setAmbienteLogado(credenciais.ambiente)
                setAutenticando(false)
                context.logar(sucesso, credenciais.ambiente)
                let pathname = sessionStorage.getItem('pathaname')
                if(pathname) {
                    navigate(pathname)
                } else {
                    navigate("/")
                }
            }
        })
       
    }

    return (
        <div className="formLogin marginAuto" style={{maxWidth:'30%'}}>
            <form onSubmit={onSubmit}>
                <h2 className="title">Autentica&ccedil;&atilde;o</h2>
                
                <div className="field">
                    <label>Nome do usuário:</label>
                    <div className="control">
                        <input className="input" name="username" placeholder="Login" type="text" onChange={onChange} required/>
                    </div>
                </div>
                <div className="field">
                    <label>Senha</label>
                    <div className="control">
                        <input className="input" name="password" placeholder="Senha" type="password" onChange={onChange} required/>
                    </div>
                </div>
                <div className="field">
                    <label>Ambiente</label><br/>
                    <div className="select" name="ambiente">
                        <select name="ambiente" onChange={onChange} required>
                            <option value="homol">Homologa&ccedil;&atilde;o</option>
                            <option value="prod">Produ&ccedil;&atilde;o</option>
                            <option value="devLocal">Dev / Local</option>
                        </select>
                    </div>
                </div>
                <div className="field" style={{textAlign:'center'}}>
                    <input type="submit" className="button is-primary" value={autenticando ? 'Aguarde...' : 'Entrar'}/>
                </div>
            </form>
        </div>
    )
}