import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import TransacaoService from "../../service/TransacaoService";
import UsuarioService from '../../service/UsuarioService';
import { Context } from '../../context/Context';
const transacaoService = new TransacaoService();
const usuarioService = new UsuarioService();
function Transaction() {
    const context = useContext(Context)
    const [transacao, setTransacao] = useState(null)

    const [atualizando, setAtualizando] = useState(false)

    let {id} = useParams();

    useEffect(() => {
        atualizarTransacao()
    }, [])

    const atualizarTransacao = () =>{
        setAtualizando(true)
        if(usuarioService.usuarioCorrente()) {
            transacaoService.getTransaction(context.state.ambienteLogado, id, (sucesso, err) => {
                if(sucesso) {
                    setAtualizando(false)
                    setTransacao(sucesso)
                    sessionStorage.setItem('transacao', JSON.stringify(sucesso))
                }
                if(err) {
                    setAtualizando(false)
                    alert("Não foi possível pesquisar pelas transações. Por favor, tente novamente mais tarde.")
                }
            });
        }
    }

    const formateData = (data) => {
        if (! data) {
            return ""
        }
        let componentes = data.split("T")
        let componentesDia = componentes[0].split("-")
        return (componentesDia[2] + "/" + componentesDia[1] + "/" + componentesDia[0]) + "  -  " + componentes[1]
    }

    if (! transacao) {
        return (
            <div className="transacao">
                <h2 className="title">Detalhes da transação</h2>
                <p>Aguarde...</p>
            </div>
        );
    }

    return (
        <div className="transacao">
            <h2 className="title">Detalhes da transação  
                <button style={{marginLeft:'1em'}} className='button is-dark' onClick={() =>atualizarTransacao()}> {atualizando ? "Atualizando...":"Atualizar"}</button>
            </h2>
            <p className='link__margin_bottom'>
                <Link className="button is-primary" to="/">Voltar</Link>    
            </p>
            <div className="content">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>UUID</td>
                            <td>{transacao.uuid}</td>
                        </tr>
                        <tr>
                            <td>Tipo</td>
                            <td>{transacao.tipo}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{transacao.status}</td>
                        </tr>
                        <tr>
                            <td>Iniciada em</td>
                            <td>{formateData(transacao.dataInicio)}</td>
                        </tr>
                        <tr>
                            <td>Finalizada em</td>
                            <td>{formateData(transacao.dataFim)}</td>
                        </tr>
                        <tr>
                            <td>Tempo de execução</td>
                            <td>{transacao.duracao}</td>
                        </tr>
                        <tr>
                            <td>Erro</td>
                            <td>{transacao.erro}</td>
                        </tr>
                        <tr>
                            <td>Tipo</td>
                            <td>{transacao.tipo}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2 className="title">Histórico</h2>
            <table className="table is-striped is-bordered is-hoverable">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {transacao.historico.map((item) => {
                        return (
                            <tr key={item.uuid}>
                                <td>{item.data}</td>
                                <td>{item.status}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <h2 className="title">Etapas</h2>
            <table className="table is-striped is-bordered is-hoverable">
                <thead className='thead'>
                    <tr>
                        <th>UUID</th>
                        <th>Tipo</th>
                        <th>Início</th>
                        <th>Fim</th>
                        <th>Duração</th>
                        <th>Sucesso</th>
                        <th>Erro</th>
                    </tr>
                </thead>
                <tbody>
                    {transacao.etapas.map((item) => {
                        return (
                            <tr key={item.uuid}>
                                <td><Link to={"/transacao/etapa/" + item.uuid}>{item.uuid}</Link></td>
                                <td>{item.tipo}</td>
                                <td>{formateData(item.dataInicio)}</td>
                                <td>{formateData(item.dataFim)}</td>
                                <td>{item.duracao}</td>
                                <td>{item.sucesso ? "Sim":"Não"}</td>
                                <td>{item.erro}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Transaction;