import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from '../../context/Context';

function HandleRedirecting() {
    const context = useContext(Context)
    const navigate = useNavigate();
    useEffect(() => {
        if(!context.state.logado && context.state.usuario == null) {
            navigate("/login")
        }else {
            navigate("/transacoes")
        }
    })

    return (
        <div></div>
    )
}

export default HandleRedirecting;