import instance from './Api';
import ambientes from '../utils/ambientes';

export default class TransacaoService {


    searchTransacoes(ambienteType, request, callback){

        let ambiente = ambientes(ambienteType)
        
        if (! ambiente) {
            callback("Ambiente desconhecido: [" + ambienteType + "]", null)
            return
        }

        let params = ""
        for(const key in  request) {
            const value = request[key]
            if(!value) {
                continue;
            }
            params = params + `${key}=${value}&`;
        }
        instance.get(ambiente.host + "/api/v1/transaction?" + params)
            .then((response) => {
                callback(response.data, null)
            })
            .catch((erro) => {
                callback(null, erro)
            })
    }

    getTransaction(ambienteType, transactionID, callback) {

        let ambiente = ambientes(ambienteType)
        
        if (! ambiente) {
            callback("Ambiente desconhecido: [" + ambienteType + "]", null)
            return
        }

        instance.get(ambiente.host + "/api/v1/transaction/" + transactionID)
        .then((response) => {
            callback(response.data, null)
        })
        .catch((erro) => {
            callback(null, erro)
        })
    }

    getStageTransaction(ambienteType, stageID, callback) {
        
        let ambiente = ambientes(ambienteType)
        
        if (! ambiente) {
            callback("Ambiente desconhecido: [" + ambienteType + "]", null)
            return
        }

        instance.get(ambiente.host + "/api/v1/transaction/stage/" + stageID)
        .then((response) => {
            callback(response.data, null)
        })
        .catch((erro) => {
            callback(null, erro)
        })
    }
}