import axios from 'axios';
import UsuarioService from './UsuarioService.js';

let user = new UsuarioService()

let instance = axios.create({
    baseURL: user.getAmbienteLogado(),
    headers: {'apikey': user.usuarioCorrente() ? user.usuarioCorrente().apiKey : ''},
    timeout: 20000
})

instance.interceptors.request.use((config) => {
    let token = user.token();
    if (token) {
        config.headers.Authorization = token
    }
    return config;
})

instance.interceptors.response.use( (response)  => {
    return response
}, (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
        user.logout()
        window.location = "/"
    }
    return Promise.reject(error)
})

export default instance