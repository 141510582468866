import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import TransacaoService from "../../service/TransacaoService";
import UsuarioService from '../../service/UsuarioService';
import { Context } from '../../context/Context';
const transacaoService = new TransacaoService();
const busca = JSON.parse(sessionStorage.getItem('busca'));
const usuarioService = new UsuarioService();
function TransactionSearch() {
    const context = useContext(Context)
    const [transacoes, setTransacoes] = useState(sessionStorage.getItem('transacoes') ? JSON.parse(sessionStorage.getItem('transacoes')) : [])
    const [buscando, setBuscando] = useState(false)
    const [carregarMais, setCarregarMais] = useState(true)
    const [carregandoMaisItens, setCarregandoMaisItens] = useState(false)
    const [queryParams, setQueryParams] = useState({
        startDate: "",
        endDate: "",
        status: "",
        transactionId: "",
        type: "",
        pageSize: 50,
        page: 0,
        order: "asc"
    });

    const getTransacoes = () => {
        setBuscando(true)
        if(busca){
            busca.page = 0
            setQueryParams(busca)
            sessionStorage.setItem("busca", JSON.stringify(busca))
        }

        if(usuarioService.usuarioCorrente()) {
            transacaoService.searchTransacoes(context.state.ambienteLogado, queryParams, (sucesso, err)=>{
                if(sucesso) {
                    setTransacoes(sucesso)
                    sessionStorage.setItem('transacoes', JSON.stringify(sucesso))
                    setBuscando(false)
                    setCarregarMais(sucesso.length === queryParams.pageSize)
                }
                if(err) {
                    setBuscando(false)
                    alert("Não foi possível pesquisar pelas transações. Por favor, tente novamente mais tarde.")
                }
            });
        }
    };

    const onChangeFormBusca = (event) => {
        let query = queryParams
        if(event.target.value){
            query[event.target.name] = event.target.value
        }else{
            query[event.target.name] = null
        }
        
        
        setQueryParams(query)
    }

    const buscar = (event) => {
        if (buscando) {
            return
        }
        setBuscando(true)
        transacaoService.searchTransacoes(context.state.ambienteLogado, queryParams, (sucesso, err)=>{
            if(sucesso) {
                setTransacoes(sucesso)
                sessionStorage.setItem('transacoes', JSON.stringify(sucesso))
                setBuscando(false)
                setCarregarMais(sucesso.length === queryParams.pageSize)
            }
            if(err) {
                setBuscando(false)
                alert("Não foi possível pesquisar pelas transações. Por favor, tente novamente mais tarde.")
            }
        })
        event.preventDefault()
    }

    const salvarBusca = () =>{
        sessionStorage.setItem("busca", JSON.stringify(queryParams))
    }


    const carregarMaisItens = () =>{
        setCarregandoMaisItens(true)
        queryParams.page++
        transacaoService.searchTransacoes(context.state.ambienteLogado, queryParams, (sucesso, err)=>{
            if(sucesso) {
                const totalTransacacoes = transacoes.concat(sucesso)
                setTransacoes(totalTransacacoes)
                sessionStorage.setItem('transacoes', JSON.stringify(totalTransacacoes))
                setCarregarMais(sucesso.length === queryParams.pageSize)
                setCarregandoMaisItens(false)
            }
            if(err) {
                setCarregandoMaisItens(false)
                alert("Não foi possível pesquisar pelas transações. Por favor, tente novamente mais tarde.")
            }
        });
    }

    const formateData = (data) => {
        if (! data) {
            return ""
        }
        let componentes = data.split("T")
        let componentesDia = componentes[0].split("-")
        return (componentesDia[2] + "/" + componentesDia[1] + "/" + componentesDia[0]) + "  " + componentes[1]
    }

    useEffect(() => {
        const callback = () => {
            getTransacoes()
        }
        callback()
    }, [])

    return (
        <>  
        <div className="transacoes">
            <h2 className="title">Transações</h2>
            <form className="formBusca" onSubmit={buscar}>
                <div className="field mr-1">
                    <label>ID:</label>
                    <div className="control">
                        <input onChange={onChangeFormBusca} defaultValue={queryParams.transactId} className="input" type="text" placeholder="UUID da transação" name="transactId"/>
                    </div>
                </div>
                <div className="field mr-1">
                    <label>Tipo:</label>
                    <div className="control">
                        <input onChange={onChangeFormBusca} defaultValue={queryParams.type} className="input" type="text" placeholder="Tipo da transação" name="type"/>
                    </div>
                </div>

                <div className="field mr-1">
                    <label>Início:</label>
                    <div className="control">
                        <input onChange={onChangeFormBusca} defaultValue={queryParams.startDate} className="input" type="text" placeholder="yyyy-MM-dd HH:mm:ss" name="startDate"/>
                    </div>
                </div>
                <div className="field mr-1">
                    <label>Fim:</label>
                    <div className="control">
                        <input onChange={onChangeFormBusca} defaultValue={queryParams.endDate} className="input" type="text" placeholder="yyyy-MM-dd HH:mm:ss" name="endDate"/>
                    </div>
                </div>
                <div className="field mr-1">
                    <label>Status:</label>
                    <div className="control">
                        <div className="select">
                            <select name="status" onChange={onChangeFormBusca} defaultValue="">
                                <option value="">Todos</option>
                                <option value="INICIADA">Iniciada</option>
                                <option value="FINALIZADA">Finalizada</option>
                                <option value="ERRO">Erro</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field mr-1">
                    <label>Ordenação:</label>
                    <div className="control">
                        <div className="select">
                            <select name="order" onChange={onChangeFormBusca} defaultValue="">
                                <option value="asc">Crescente</option>
                                <option value="desc">Decrescente</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="field mr-1" style={{marginLeft:'1.0em'}}>
                    <div className="control">
                        <br/>
                        <input type="submit" className="button is-primary" value={buscando ? 'Aguarde...' : 'Buscar'}/>
                    </div>
                </div>
            </form>
            <hr/>
            <div className="transacoes">
                <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                    <thead className='thead'>
                        <tr>
                            <th>ID</th>
                            <th>Status</th>
                            <th>Tipo</th>
                            <th>Data de início</th>
                            <th>Data de finalização</th>
                            <th>Duração</th>
                            <th>Sucesso</th>
                            <th>Erro</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transacoes.map((item, index) => {
                            return (
                                <tr key={'transacao-' + item.uuid}>
                                    <td>
                                        <Link to={"/transacao/" + item.uuid} onClick={() => salvarBusca()}>{item.uuid} </Link>
                                    </td>
                                    <td>{item.status}</td>
                                    <td>{item.tipo}</td>
                                    <td>{formateData(item.dataInicio)}</td>
                                    <td>{formateData(item.dataFim)}</td>
                                    <td>{item.duracao}</td>
                                    <td>{item.sucesso ? "Sim":"Não"}</td>
                                    <td>{item.erro}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {carregarMais ? (
            <p className='textAlignCenter mt-4'><button className='button is-dark' onClick={() =>carregarMaisItens()}> {carregandoMaisItens ? "Carregando mais itens...": "Carregar mais"}</button></p>
                
            ):""}            
        </div>
        </>
    );
  }
  
  export default TransactionSearch;