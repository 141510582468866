import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import ActuatorService from '../../service/ActuatorService';
import UsuarioService from '../../service/UsuarioService';
import { Context } from '../../context/Context';

const usuarioService = new UsuarioService();
const actuatorService = new ActuatorService();

function Health() {
    const context = useContext(Context)
    const [buscando, setBuscando] = useState(false)
    const [infos, setInfos] = useState("")
    const [status, setStatus] = useState("")
    const [erros, setErros] = useState(null)
    let exposicao = ""

    useEffect(() => {        
        buscar()
    }, [])

    const buscar = () => {
        setBuscando(true)
        if(usuarioService.usuarioCorrente()) {
            actuatorService.health(context.state.ambienteLogado, (sucesso, err) => {
                if(sucesso) {
                    setInfos(JSON.stringify(sucesso, null, 2) )
                    setStatus(sucesso.status)
                    sessionStorage.setItem('actuatorHealth', JSON.stringify(sucesso))
                    setBuscando(false)
                    if (sucesso.status === 'DOWN'){
                      alert(sucesso)
                    }
                }
                if(err) {
                    setBuscando(false)
                    setErros(JSON.stringify(err.data, null, 2) )        
                    alert("Não foi possível buscar registros")
                }
            });
        }
    }

    if (!infos) {
      return (
          <div className="transacao">
              <p>Aguarde...</p>
          </div>
      );
    }

    if (!erros) {
        exposicao = (
        <div>
            <div className="mt-5">   
                <textarea className="form-control mt-5 textarea" defaultValue={infos} rows={30} ></textarea>
            </div>
        </div>)
    }else {
        exposicao = (
        <div>
            <h2 className='subtitle mt-4 mb-3'>Erros encontrados</h2>
            <div className="mt-5">
                <textarea className="form-control mt-5 textarea" defaultValue={erros} rows={30} ></textarea>
            </div>
        </div>)
    }

    return (
        <div className="transacoes">
            <h2 className="title">Health check
                <button className='button is-dark ml-4' onClick={() =>buscar()}> {buscando ? "Atualizando...":"Atualizar"}</button>
            </h2>
            <p className='link__margin_bottom'>
                <Link className="button is-primary" to="/">Voltar</Link>    
            </p>
            <div>
              {status ?
                (
                    <div>
                        <h3>Status: {status}</h3>
                    </div>
                ):""}
            </div>
            <div>
                {exposicao}
            </div> 
        </div>
        
    )
}

export default Health;