import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import TransacaoService from "../../service/TransacaoService";
import UsuarioService from '../../service/UsuarioService';
import { Context } from '../../context/Context';
const transacaoService = new TransacaoService();
const usuarioService = new UsuarioService();
function Etapa() {
    const context = useContext(Context)
    const [buscando, setBuscando] = useState(false)
    const [etapa, setEtapa] = useState(null);
    let {id} = useParams();

    useEffect(() => {
        atualizarEtapa()
    },[])

    const formateData = (data) => {
        if (! data) {
            return ""
        }
        let componentes = data.split("T")
        let componentesDia = componentes[0].split("-")
        return (componentesDia[2] + "/" + componentesDia[1] + "/" + componentesDia[0]) + "  -  " + componentes[1]
    }

    const atualizarEtapa = () =>{
        setBuscando(true)
        if(usuarioService.usuarioCorrente()) {
            transacaoService.getStageTransaction(context.state.ambienteLogado, id, (sucesso, err) => {
                if(sucesso) {
                    setEtapa(sucesso)
                    setBuscando(false)
                    sessionStorage.setItem('etapa', JSON.stringify(sucesso))
    
                }
                if(err) {
                    alert("Erro ao buscar etapa: " + err)
                    setBuscando(false)
                }
            });
        }
    }

    if (! etapa) {
        return (
            <div className="etapa">
                <h2 className="title">Detalhes da etapa</h2>
                <p>Aguarde...</p>
            </div>
        );
    }


    return (
        <div className="etapa">
            <h2 className="title">Detalhes da etapa <button className='button is-dark' onClick={() =>atualizarEtapa()}> {buscando ? "Atualizando...":"Atualizar"}</button></h2>
            <p className='link__margin_bottom'>
                <Link className="button is-primary" to={"/transacao/" + etapa.transacaoUuid}>Voltar</Link>    
            </p>
            <table className="table">
                <tbody>
                    <tr>
                        <td>UUID</td>
                        <td>{etapa.uuid}</td>
                    </tr>
                    <tr>
                        <td>Nome</td>
                        <td>{etapa.tipo}</td>
                    </tr>
                    <tr>
                        <td>Inicio</td>
                        <td>{formateData(etapa.dataInicio)}</td>
                    </tr>
                    <tr>
                        <td>Fim</td>
                        <td>{formateData(etapa.dataFim)}</td>
                    </tr>
                    <tr>
                        <td>Duração</td>
                        <td>{etapa.duracao}</td>
                    </tr>
                    <tr>
                        <td>Sucesso</td>
                        <td>{etapa.sucesso ? "Sim":"Não"}</td>
                    </tr>
                    <tr>
                        <td>Erro</td>
                        <td>{etapa.erro}</td>
                    </tr>
                </tbody>
            </table>
            <h2 className="title">Arquivos</h2>
            <table className="table is-striped is-hoverable is-fullwidth">
                <thead className='thead'>
                    <tr>
                        <td>UUID</td>
                        <td>Data</td>
                        <td>Nome</td>
                        <td>URL</td>
                        <td>Cabeçalhos</td>
                        <td>Conteúdo</td>
                    </tr>
                </thead>
                <tbody className='tbody'>
                    {etapa.arquivos.map((arquivo) => {
                        return (
                            <tr key={arquivo.uuid}>
                                <td>{arquivo.uuid}</td>
                                <td>{formateData(arquivo.dateCreated)}</td>
                                <td>{arquivo.nome}</td>
                                <td style={{overflowWrap:'anywhere'}}>{JSON.stringify(arquivo.url)}</td>
                                <td style={{overflowWrap:'anywhere'}}>{JSON.stringify(arquivo.cabecalhos)}</td>
                                <td style={{overflowWrap:'anywhere'}}>{arquivo.conteudo}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default Etapa;