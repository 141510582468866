import instance from './Api';
import ambientes from '../utils/ambientes';

export default class ActuatorService {


    info(ambienteType, callback){

        let ambiente = ambientes(ambienteType)
        
        if (! ambiente) {
            callback("Ambiente desconhecido: [" + ambienteType + "]", null)
            return
        }

        instance.get(ambiente.host + "/actuator/info")
        .then((response) => {
            callback(response.data, null)
        })
        .catch((erro) => {
            callback(null, erro)
        })
    }

    health(ambienteType, callback) {

        let ambiente = ambientes(ambienteType)
        
        if (! ambiente) {
            callback("Ambiente desconhecido: [" + ambienteType + "]", null)
            return
        }

        instance.get(ambiente.host + "/actuator/health")
        .then((response) => {
            callback(response.data, null)
        })
        .catch((erro) => {
            callback(null, erro)
        })
    }
}