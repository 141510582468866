import axios from 'axios';
import ambientes from '../utils/ambientes';

export default class UsuarioService {

    /**
     * Retorna o usuario atualmente autenticado na aplicação
     */
    usuarioCorrente() {
        let conteudo = localStorage.getItem('usuario')
        if (conteudo) {
            return JSON.parse(conteudo)
        } else {
            return null;
        }
    }

    setUsuarioCorrente(usuario){
        localStorage.setItem('usuario', JSON.stringify(usuario))
    }

    removeUsuarioCorrente() {
        localStorage.removeItem('usuario')
    }

    token() {
        let corrente = this.usuarioCorrente()
        if (corrente) {
            return corrente.token
        } else {
            return null
        }
    }

    setAmbienteLogado(ambiente) {
        localStorage.setItem("ambienteLogado", ambiente)
    }

    getAmbienteLogado() {
        let ambienteLogado = localStorage.getItem("ambienteLogado")
        if (ambienteLogado) {
            return ambienteLogado
        } else {
            return null;
        }
    }

    /**
     * Realiza o logout do usuário na aplicação
     * 
     */
    logout() {
        localStorage.clear();
        sessionStorage.clear();
        window.location = "/"
    }

    /**
     * Realiza a autenticação no sistema.
     * 
     * Credenciais consiste em um objeto com dois campos: username, password
     * @param {*} credenciais 
     * @param {*} callback 
     */
    auth(credenciais, callback) {

        let ambiente = ambientes(credenciais.ambiente)
        if (! ambiente) {
            callback("Ambiente desconhecido: [" + credenciais.ambiente + "]", null)
            return
        }

        axios.post(ambiente.host + '/api/v1/auth', credenciais)
                .then((response) => {
                    localStorage.setItem('usuario', JSON.stringify(response.data))
                    callback(null, response.data)
                })
                .catch((error) => {
                    localStorage.removeItem('usuario')
                    callback(error, null)
                })
    }

}