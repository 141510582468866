export default function ambiente(nome) {
    switch (nome) {
        case "devLocal":
            return {
                host: "http://localhost:8080",
                nome: "Dev - Local"
            }
        case "homol":
            return {
                host: "https://tempgateway.itexto.com.br",
                nome: "Homologação"
            }
        case "prod":
        default:
            return {
                host: "https://tempgateway.itexto.com.br",
                nome: "Produção"
            }
    }
}