import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import ActuatorService from '../../service/ActuatorService';
import UsuarioService from '../../service/UsuarioService';
import { Context } from '../../context/Context';

const usuarioService = new UsuarioService();
const actuatorService = new ActuatorService();

function Info() {
    const context = useContext(Context)
    const [buscando, setBuscando] = useState(false)
    const [infos, setInfos] = useState("")

    useEffect(() => {        
        buscar()
    }, [])

    const buscar = () => {
        setBuscando(true)
        if(usuarioService.usuarioCorrente()) {
            actuatorService.info(context.state.ambienteLogado, (sucesso, err) => {
                if(sucesso) {
                    setInfos(JSON.stringify(sucesso, null, 2) )
                    sessionStorage.setItem('info', JSON.stringify(sucesso))
                    setBuscando(false)
                }
                if(err) {
                    setBuscando(false)
                    alert("Não foi possível buscar registros")
                }
            });
        }
    }

    if (! infos) {
      return (
          <div className="transacao">       
              <p>Aguarde...</p>
          </div>
      );
    }

    return (
        <div className="transacoes">
            <h2 className="title">Info
                <button className='button is-dark ml-4' onClick={() =>buscar()}> {buscando ? "Atualizando...":"Atualizar"}</button>
            </h2>
            <p className='link__margin_bottom'>
                <Link className="button is-primary" to="/">Voltar</Link>    
            </p>
            <div className="mt-5"> 
                <textarea className="form-control mt-5 textarea" defaultValue={infos} rows={40} ></textarea>
            </div>
        </div>
        
    )
}
export default Info;